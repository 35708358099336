<template>
  <div class="all w-full md:pt-4">
            <div class="titre">
              Identifiez-vous
            </div>

            <div class="md:mt-10 mt-5 w-full">
              <div class="sous-titre">
                Email
              </div>

              <inputo placeholder="aa@gmail.com" type="email" class="mt-3" @info="retourMail"/>

              <div class="sous-titre mt-4">
                Mot de passe
              </div>

              <div class="md:flex input mt-4 h-full" @click="focusActiv">
                <input type="password" v-model="password" class="h-full w-full">
              </div>

              <div class="md:mt-12 mt-4 w-full">
                <div
                  v-if="error !== null"
                  class="text-center error mb-2"
                >
                  {{ error }}
                </div>

                <div
                  class="button w-full md:mt-0 mt-4"
                  @click="continues"
                >
                  <div class="loader" v-if="charge === true"></div>
                  <span v-if="charge === false">
                    Continuer
                  </span>
                </div>
              </div>
            </div>
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'Success',
  components: {
    inputo
  },
  props: {
  },

  data () {
    return {
      charge: false,
      error: null,
      email: '',
      password: ''
    }
  },

  methods: {
    focusActiv () {
      this.$refs.numero.focus()
    },

    condition () {
      window.open('https://www.atlantiqueassurances.bj/', '_blank')
    },

    continues () {
      this.charge = true
      this.error = null
      if (this.email.length > 0 && this.password.length > 0) {
        http.post(apiroutes.baseURL + apiroutes.login, {
          username: this.email,
          password: this.password
        })
          .then(response => {
            console.log(response)
            this.$store.dispatch('login', response.data.access_token)
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.error = 'Identifiant incorrect'
            this.charge = false
            console.log('erreur')
            console.log(error)
          })
      } else {
        this.charge = false
        this.error = 'Veillez entrer des identifiants valid pour continuer'
      }
    },

    retourMail (answer) {
      this.email = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../../assets/style/sass/mixin";

    .alert{
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      color: #000000;
      span{
        text-decoration-line: underline;
        color: #0269AD;
        cursor: pointer;
      }
    }
    .error{
      font-size: 14px;
      color: red;
    }
    .titre{
      font-weight: 500;
      font-size: 36px;
      line-height: 48px;
      color: #000000;
    }
    .sous-titre{
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #909090;
    }
    .input{
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 10px;
      height: 60px;
    }
    .button{
      background-color: $base-color;
      height: 60px;
      font-weight: normal;
      font-size: 24px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .titre{
        font-size: 26px;
      }
      .sous-titre{
        font-size: 16px;
      }
      .input{
        height: 49px;
      }
      .button{
        height: 49px;
        font-size: 20px;
      }
    }

    @media screen and (max-width: 700px){
      .titre{
        font-size: 24px;
      }
      .sous-titre{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
      }
      .input{
        height: 47px;
      }
      .button{
        height: 47px;
        font-size: 20px;
      }
      .alert{
        text-align: center;
      }
      .all{
        padding-top: 100px;
      }
    }
</style>
