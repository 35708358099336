<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal">
        <div
          class="success-container h-full p-5"
        >
          <div class="flex items-center">
            <div class="titre w-2/3">Utilisateurs</div>
            <div class="w-1/3 text-right cursor-pointer deconnexion" @click="logout">Deconnexion</div>
          </div>

          <inputo placeholder="Tapez pour rechercher" @info="retourSearch" class="mt-3"/>

          <div>

          </div>

          <div class="mt-6">
            <div class="bloc flex p-3 items-center mb-3 cursor-pointer" v-for="(item, index) in donnee" :key="index" @click="selected(item)">
              <svg-icon
                name="user"
                class="h-10 mr-4"
                original
                color="black"
                v-if="item.pictureUrl === ''"
              />
              <img :src="item.pictureUrl" alt="" class="h-10 w-10 rounded-full mr-4" v-if="item.pictureUrl !== ''">
              <div class="w-2/3 text-black">
                <div class="sous-titre">
                  <span v-if="item.fullname !== ''">{{ item.fullname }}</span>
                  <span v-if="item.fullname === ''">-</span>
                </div>
                <div class="contenu">{{ item.telephone }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupBase2'
import inputo from '@/components/helper/form/input'
import { db } from '@/main'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'Success',
  components: { PopupBase, inputo },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      notification: [],
      firebaseId: null,
      recherche: '',
      charge: true,
      location: []
    }
  },

  computed: {
    donnee: function () {
      let retour = this.location

      if (this.recherche !== '') {
        let authorNameSearchString = this.recherche
        authorNameSearchString = authorNameSearchString.toLowerCase()
        retour = retour.filter((item) => {
          if ((item.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 || (item.telephone.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
            return item
          }
        })
      }
      return retour
    }
  },

  mounted () {
    this.allUser()
  },

  methods: {
    allUser () {
      http.get(apiroutes.baseURL + apiroutes.allClient)
        .then(response => {
          this.charge = false
          const locations = response.data
          for (const i in locations) {
            this.firebaseTrack(locations[i], i)
          }
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
          if (error.status === 401) {
            this.$store.dispatch('logout')
              .then(response => {
                this.$router.push('/login')
              })
              .catch(error => {
                console.log('erreur')
                console.log(error)
              })
          }
        })
    },

    logout () {
      this.$store.dispatch('logout', null)
      window.location.replace('http://aab.bj')
    },

    firebaseTrack (item, index) {
      db.collection('users').doc(item.firestoreId)
        .get()
        .then((response) => {
          const result = response.data()
          result.api = item
          this.location.push(result)
          console.log(this.location)
        })
        .catch((error) => {
          console.log('Error getting documents: ', error)
        })
    },

    selected (item) {
      this.$store.dispatch('saveConcern', item)
      this.$emit('oga', true)
    },

    retourSearch (answer) {
      this.recherche = answer
    },

    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      // this.wantToMakeOffer = answer
      // this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 100%;
    }
    .svg-icon{
      fill: white;
    }
    .date{
      font-size: 10px;
      font-weight: 300;
    }
    .success-container {
      width: 100%;
      background-color: #F5F5F5;
      padding: 0px;
      height: 100vh;
      border-radius: 0px;
      font-family: $font-default;
      overflow-y: scroll;
    }
    .deconnexion{
      color: red;
    }
    .deconnexion:hover{
      color: #c30303;
    }
    .success-container::-webkit-scrollbar {
      display: none;
    }
    .titre {
      font-weight: 500;
      font-size: 36px;
      line-height: 43px;
      color: black;
    }
    .sous-titre{
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }
    .contenu{
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
    }
    .bloc{
      background: #ffffff;
      border: .4px solid #badcf5;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .read{
      border: none;
      background-color: #e3e7ea;
    }
    .bloc:hover{
     box-shadow: 2px -1px 9px 0px #00000033;
    }
    img{
      object-fit: cover;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
      }
    }
</style>
