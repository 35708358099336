<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container md:flex h-full"
        >
          <div class="md:w-1/3 w-full bloc1 p-4">
            <div class="w-10 h-10 rounded-full close md:mt-0 mt-3 flex items-center cursor-pointer justify-center" @click="fermer">
              <div>X</div>
            </div>
            <div class="flex items-center justify-center">
              <img src="../../assets/images/png/sign.png" alt="" class="md:mt-20 mt-5">
            </div>
          </div>

          <div class="md:w-2/3 w-full p-5">
            <sign @oga="retourLogin"/>
            <div class="flex justify-center" v-if="charge"><div class="loader text-center"></div></div>
          </div>

        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import sign from '@/components/cedro/signupAdmin/sign'

export default {
  name: 'Success',
  components: { PopupBase, sign },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      telephone: '',
      position: 1,
      code: null,
      error: null,
      charge: false,
      activeUser: true
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.activeOk = false
      this.$emit('oga', false)
    },

    yes () {
      this.activeOk = false
      this.$emit('oga', true)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
      this.activeOk = false
    },

    retourLogin (answer) {
      this.$emit('oga', true)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 60%;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .bloc1{
      background: #F5F5F5;
      border-radius: 12px 0px 0px 12px;
    }
    .close{
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      color: #FF4B55;
    }
    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 80%;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 100%;
      }
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .bloc1{
        display: none;
      }
      .close{
        width: 42px;
        height: 42px;
        position: absolute;
      }
      .cardinal{
        width: 100%;
      }
      img{
        height: 103px;
      }
    }
</style>
